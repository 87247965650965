export default {
    api: {
        baseurl: "https://api.vue.eatery-portal.com/",
        version: "api/v0.1"
    },

    // 承認要求
    approval_request: {
        decline: 0,      // なし
        approved: 1,    // あり
    },

    // 招待送信タイプ
    invitation_send_type: {
        email: 0,      // メール
        line: 1,      // LINE
        sms: 2,      // SMS
        qr: 3,      // QR
        other: 9,    // その他
    },

    // アプリ説明サイト
    external_info: {
        pc: {
            terms: "/terms/",
            privacy: "https://corp.infomart.co.jp/privacy/",
            company: "https://www.infomart.co.jp/information/index.asp",
            external_send_discipline_guidelines: "https://corp.infomart.co.jp/external_transmission",
            environment: "/environment/",
        },
        sp: {
            terms: "/terms/sp/",
            privacy: "https://corp.infomart.co.jp/privacy/",
            company: "https://www.infomart.co.jp/sp/information/index.asp",
            external_send_discipline_guidelines: "https://corp.infomart.co.jp/external_transmission",
            environment: "/environment/sp/",
        }
    },

    // 利用申し込み
    request: {
        vmanage: {
            promotion: "https://v-manage.infomart.co.jp/"
        },
    },

    // ファイル管理
    files: {
        upload_video_split_size: 50,  //  動画アップロード割サイズ（MB）
    },
}