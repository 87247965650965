import Index from '@/components/portal/Index';
import Home from '@/components/portal/home/HomeIndex';

export default
    [
        {
            path: '/',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'homemain',
                    component: Home,
                    meta: { title: 'ホーム' },
                }
            ]
        }
    ]

