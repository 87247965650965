<template>
  <header class="sp-toolbar">
    <div class="tool-account">
      <div class="tool-account-avatar">
        <v-avatar size="36" style="margin: 4px 0 6px" @click="toolbarMenu = !toolbarMenu">
          <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" />
          <v-icon v-else color="blue">mdi-account</v-icon>
        </v-avatar>
      </div>
      <div class="sp-toolbar-menu" v-if="toolbarMenu">
        <div class="sp-toolbar-menu-inner">
          <div class="sp-toolbar-account">
            <div class="sp-toolbar-account-avatar">
              <v-avatar size="40" @click="toolbarMenu = !toolbarMenu">
                <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" />
                <v-icon v-else color="blue">mdi-account</v-icon>
              </v-avatar>
            </div>
            <div class="sp-toolbar-account-name">
              {{ getUserName() }}
            </div>
          </div>
          <div class="sp-toolbar-belongs">
            <div class="sp-toolbar-company">
              {{ getUserCompanyFullName() }}
            </div>
            <div class="sp-toolbar-brand">
              {{ getUserBrandName() }}
            </div>
            <div class="sp-toolbar-shop">
              {{ getUserShopName() }}
            </div>
            <div class="sp-toolbar-role">
              {{ getUserRoleName() }}
            </div>
          </div>
          <div class="sp-toolbar-change">
            <button @click="viewChangeShop()">
              <i class="mdi mdi-cached"></i>
              <span>店舗切り替え</span>
            </button>
          </div>
          <div class="sp-toolbar-devider"></div>
          <div class="sp-toolbar-latest">
            <button @click="pageMove('/auth/getuserinfo')">
              最新情報を取得
            </button>
          </div>
          <div class="sp-toolbar-auth">
            <button @click="pageMove('/auth/signout')">サインアウト</button>
          </div>
          <div class="sp-toolbar-close">
            <button @click="toolbarMenu = false">
              <i class="mdi mdi-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="tool-name">
      <img :src="getLogoFPortal()" alt="" style="width: 140px" />
    </div>
    <div class="tool-change">
      <button><i class="mdi mdi-cached"></i></button>
    </div>
    <ChangeShopModal v-if="change_shop_modal" :close="changeShop" />
    <ConfirmModal v-if="confirm_modal" title="編集を中断します。" message="編集を中断します。よろしいですか？" :close="execMove" />
  </header>
</template>

<script>
import ChangeShopModal from "@/components/common/change_shop/SpChangeShopModal";
import ConfirmModal from "@/components/common/ConfirmModal";
export default {
  props: ["parents"],
  components: { ChangeShopModal, ConfirmModal },
  setup() {
    const { getUserName, getUserCompanyFullName, getUserBrandName, getUserRoleName,
      isUserHeadShop, getUserShopName, getAccountAvatarUrl, isFranchiseOwnerHead,
      getUserFcOwnerBrandName, getUserCompanyName } = require('@/helpers/auth/auth_util')
    const { getPortalHeaderIconLinks } = require('@/helpers/portal/portal_util')
    const { pageMove, getLogoFPortal, openExternalWindow } = require('@/helpers/common/common_util')
    return {
      getUserName,
      getUserCompanyFullName,
      getUserBrandName,
      getUserRoleName,
      isUserHeadShop,
      getUserShopName,
      getAccountAvatarUrl,
      isFranchiseOwnerHead,
      getUserFcOwnerBrandName,
      getUserCompanyName,
      getPortalHeaderIconLinks,
      pageMove,
      getLogoFPortal,
      openExternalWindow,
    }
  },
  data: () => ({
    url_avatar: true,

    change_service: false,
    change_shop_modal: false,
    confirm_modal: false,

    toolbarMenu: false,
  }),

  methods: {
    checkHeaderLink(link) {
      this.debugLog(link);
      if (link.external) {
        this.openExternalWindow(link.external);
      } else {
        this.pageMove(link.to);
      }
    },

    viewChangeShop() {
      this.change_shop_modal = true;
    },
    changeShop() {
      this.change_shop_modal = false;
    },

    // 遷移確認
    confirmMove(event) {
      if (this.parents.is_move_confirm) {
        this.save_event = event;
        this.confirm_modal = true;
        return true;
      }
      return false;
    },
    execMove(is) {
      // eslint-disable-line
      if (is) {
        // eslint-disable-line
      }
      this.confirm_modal = false;
    },
  },
};
</script>

