<template>
  <div>
    <v-alert v-show="success && success != ''" border="top" color="info">
      {{ success }}
    </v-alert>
    <v-alert v-show="error && error != ''" border="top" color="red-lighten-2">
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: { success: String, error: String },
  data: () => ({}),
  created() { },
};
</script>
