export default
    [
        {
            path: '/files',
            component: () => import('@/components/portal/Index'),
            children: [
                {
                    path: '',
                    name: 'files-files',
                    component: () => import('@/components/portal/file/IndexFile'),
                    meta: { title: 'ファイル管理', policy: { function_cd: "files", crud: ['c', 'r', 'u', 'd'], is_sp: false } },
                },
            ]
        }
    ]

