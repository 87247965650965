<template>
  <div>
    <SpPage v-if="isDisplayModeSP()" :parents="parents" />
    <PcPage v-else :parents="parents" />
    <form name="codessoform" style="display: none">
      <input type="hidden" name="UID" value="" />
      <input type="hidden" name="PWD" value="" />
      <input type="hidden" name="URL" value="" />
      <input type="hidden" name="CS" value="2" />
      <input type="hidden" name="userlogin" value="1" />
      <input type="hidden" name="_type" value="" />
    </form>
  </div>
</template>

<script>
import PcPage from "./PcHome";
import SpPage from "./SpHome";
export default {
  setup() {
    const { isDisplayModeSP } = require('@/helpers/common/common_util')
    return { isDisplayModeSP, }
  },
  props: { parents: Object },
  components: { PcPage, SpPage },
};
</script>
