import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "refrigerator/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "refrigerator/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, facility) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "refrigerator/create",
            query: { staff_id: staff_id, facility: facility },
        })
    },

    // 更新
    update(apiconf, staff_id, facility) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "refrigerator/update",
            query: { staff_id: staff_id, facility: facility },
        })
    },

    // 店舗設備
    getShopList(apiconf, staff_id, shop_id, page) {
        const query = { staff_id: staff_id, page: page }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/refrigerator/list",
            query: query,
        })
    },
    searchShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/refrigerator/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    createShop(apiconf, staff_id, facility) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/refrigerator/create",
            query: { staff_id: staff_id, facility: facility },
        })
    },
    updateShop(apiconf, staff_id, facility) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/refrigerator/update",
            query: { staff_id: staff_id, facility: facility },
        })
    },
}