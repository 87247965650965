export default
    [
        {
            path: '/forum',
            component: () => import('@/components/portal/Index'),
            children: [
                {
                    path: '',
                    name: 'forum',
                    component: () => import('@/components/portal/forum/IndexForum'),
                    meta: { title: '掲示板', icon: "mdi-card-text-outline", policy: { function_cd: "forum", crud: ['r'] } },
                },
                {
                    path: ':forum_id',
                    name: 'forum-id',
                    component: () => import('@/components/portal/forum/IndexForum'),
                    meta: { title: '掲示板', icon: "mdi-card-text-outline", policy: { function_cd: "forum", crud: ['r'] } },
                }
            ]
        }
    ]

