import storeAuth from "@/helpers/store/storeAuthHelper"
import authApi from "@/helpers/auth/auth_api"
import { isServiceTimemng } from "@/helpers/environment"
import { errorLog } from "@/helpers/common/datadog"
import { isSystemApp, isSystemAndroidApp, getRouteName } from "@/helpers/common/common_util"

// アカウント設定
export async function reloadAccountSpec() {
  if (!storeAuth.storeGetAccountId()) return
  try {
    if (isServiceTimemng()) {
      // MEMO:当面設定はタイマネだけで利用
      const specs = await authApi.apiSpecAccountList()
      storeAuth.storeSetAccountSpecs(specs)
    }
  } catch (err) {
    // MEMO:エラーは無視
    errorLog(getRouteName(), "reloadAccountSpec", err);
  }
}
function _getAccountSpec(spec_key) {
  const specs = storeAuth.storeGetAccountSpecs()
  if (specs) {
    const spec = specs.find(v => v.spec_key == spec_key)
    if (spec) return spec.spec_value
  }
}
async function _saveAccountSpec(spec_key, value) {
  let specs = storeAuth.storeGetAccountSpecs()
  if (specs) {
    let spec = specs.find(v => v.spec_key == spec_key)
    if (spec) {
      spec.spec_value = value
      storeAuth.storeSetAccountSpecs(specs)
      try {
        await authApi.apiSpecAccountUpdate([{
          spec_key: spec_key,
          spec_value: value,
          spec_value_type: spec.spec_value_type
        }])
      } catch (err) {
        // MEMO:エラーは無視
        errorLog(getRouteName(), "saveAccountSpecs" + spec_key, err);
      }
    }
  }
}

// 企業設定
export async function reloadCompanySpec() {
  if (!storeAuth.storeGetAccountId()) return
  try {
    if (isServiceTimemng()) {
      // MEMO:当面設定はタイマネだけで利用
      const specs = await authApi.apiSpecCompanyGetTaskScheduleUiVer()
      storeAuth.storeSetCompanySpecs(specs)
    }
  } catch (err) {
    // MEMO:エラーは無視
    errorLog(getRouteName(), "reloadCompanySpec", err);
  }
}
export function setCompanySpecsTaskScheduleUiVer(value) {
  let specs = storeAuth.storeGetCompanySpecs()
  let spec = specs.find(v => v.spec_key == 'TaskScheduleUIVersion')
  if (spec) {
    specs.map(v => {
      if (v.spec_key == 'TaskScheduleUIVersion') v.spec_value = value
    })
  } else {
    specs.push({
      spec_key: "TaskScheduleUIVersion",
      spec_value: value,
    })
  }
  storeAuth.storeSetCompanySpecs(specs)
}
export function getCompanySpecsTaskScheduleUiVer() {
  // 設定が無ければ最新バージョン
  let specs = storeAuth.storeGetCompanySpecs()
  let spec = specs.find(v => v.spec_key == 'TaskScheduleUIVersion')
  return spec ? spec.spec_value : 1
}

// 直近タスクの通知（ビープ音）
export function getAccountSpecBeepBeforeTaskDeadline() {
  return _getAccountSpec("BeepBeforeTaskDeadline")
}
export async function saveAccountSpecBeepBeforeTaskDeadline(value) {
  return await _saveAccountSpec("BeepBeforeTaskDeadline", value)
}
export function getBeepBeforeTaskDeadlines() {
  return BeepBeforeTaskDeadlines
}
export function playBeepBeforeTaskDeadline(key) {
  if (isServiceTimemng()) {
    const beep = BeepBeforeTaskDeadlines.find(v => v.key == key)
    if (beep) {
      // タスクビープ音再生
      if (isSystemApp() && !isSystemAndroidApp()) {
        // iOS端末のみ有効
        document.getElementById("audioSource").src = require("@/assets/beep/" + beep);
        document.getElementById("audioPlayback").load();
        document.getElementById("audioPlayback").play();
      }
    }
  }
}
// 直近タスクの通知（プッシュ通知）
export function getAccountSpecReceivePushNotification() {
  return _getAccountSpec("ReceivePushNotification")
}
export async function saveAccountSpecReceivePushNotification(value) {
  return await _saveAccountSpec("ReceivePushNotification", value)
}

export default {
  // アカウント設定
  reloadAccountSpec,

  // 企業設定
  reloadCompanySpec,
  setCompanySpecsTaskScheduleUiVer,
  getCompanySpecsTaskScheduleUiVer,

  // 直近タスクの通知（ビープ音）
  getAccountSpecBeepBeforeTaskDeadline,
  saveAccountSpecBeepBeforeTaskDeadline,
  getBeepBeforeTaskDeadlines,
  playBeepBeforeTaskDeadline,

  // 直近タスクの通知（プッシュ通知）
  getAccountSpecReceivePushNotification,
  saveAccountSpecReceivePushNotification,
}

const BeepBeforeTaskDeadlines = [
  { key: 1, text: "通知音１", value: "one35.mp3" },
  { key: 2, text: "通知音２", value: "one03.mp3" },
  { key: 3, text: "通知音３", value: "one18.mp3" },
]

