<template>
  <div>
    <CommonHeader :parents="parents" />
    <AppAlert v-if="notifications_for_bar" :notifications_for_bar="notifications_for_bar" />
    <main class="sp-main">
      <div class="sp-container">
        <MessageViewer :error="error" />
        <!-- 一人目のユーザーの場合はマイタスク表示 -->
        <div class="sp-card sp-mytask-alert" v-if="is_mytask_navi">
          <div class="sp-card-title">Myタスクを完了させましょう</div>
          <div class="sp-card-text">
            スマートフォン、タブレットは一部機能を制限しているため、PCで登録してください。<br />
            （PC版は「F-Portal ログイン」と検索ください。）
          </div>
        </div>

        <div class="sp-notification-nav">
          <div class="sp-notification-nav-inner">
            <div class="font-weight-bold">
              <v-icon size="20" color="#666">mdi-bell</v-icon>
              <span class="ml-2">通知</span>
            </div>
            <div class="mt-4">
              <div v-for="(item, i) in service_types" class="sp-notification-nav-item" :key="i">
                <button @click="clickNotification(item)">
                  <span>{{ item.text }}</span>
                  <!-- 件数、新着サイン -->
                  <span v-if="getServiceStatsNewCount(item)" class="sp-notification-nav-item-badge-new">
                    新着{{ getServiceStatsNewCount(item) }}
                  </span>
                  <!-- <span v-else-if="getServiceStatsOldCount(item)" class="sp-notification-nav-item-badge-count">
                    {{ getServiceStatsOldCount(item) }}
                  </span> -->
                </button>
              </div>
            </div>
            <!-- 通知が0件の場合 -->
            <div class="mt-4" style="font-size:12px;">
              <div>新着：過去72時間以内の通知</div>
            </div>
          </div>
        </div>

        <div class="sp-card mt-6">
          <div class="sp-card-devider-head">
            <div class="sp-card-devider-title">サービス</div>
            <div class="sp-card-devider-link">
              <button @click="pageMove('/myapp')">すべてのサービス</button>
            </div>
          </div>
          <div class="sp-card-services">
            <div class="sp-card-service" v-for="menu in menus" :key="menu.link_cd">
              <button @click="portalMenuMove(this, menu)">
                <span class="sp-card-service-left">
                  <img v-if="menu.link_data.img" :src="getPortalServiceImagePath(menu.link_data.img)" />
                  <i v-else :class="'mdi ' + menu.link_data.icon"></i>
                </span>
                <span class="ml-2">
                  {{ menu.link_name }}
                </span>
              </button>
            </div>

            <div class="sp-card-service" v-for="menu in test_menus" :key="menu.link_cd">
              <button @click="pageMove(menu)">
                <span class="sp-card-service-left">
                  <!-- <i :class="'mdi ' + menu.icon" style="color: green"></i> -->
                  <v-icon size="24" color="#008000" class="mr-0">{{ menu.icon }}</v-icon>
                </span>
                <span class="ml-2">(テスト){{ menu.title }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="sp-useful-card">
          <div class="sp-card-title-underline">SSO設定</div>
          <div class="sp-card-text">
            この設定を行うことで、他のアプリケーションやサービスとの連携がスムーズになり、F-Portalをより一層便利に利用していただけます。<br />
          </div>
          <div class="sp-card-text-link">
            <button @click="pageMove('linked_services')">SSOの設定</button>
          </div>
        </div>
      </div>
      <!-- <div class="sp-microphone">
        <button
          @click="clickRecording()"
          :class="
            recordingFlg ? 'sp-microphone-btn-active' : 'sp-microphone-btn'
          "
        >
          <i class="mdi mdi-microphone"></i>
        </button>
      </div> -->
    </main>
    <CommonFooter />
    <ExecutingOverlay ref="execute" />
  </div>
</template>

<script>
import CommonHeader from "@/components/service/SpHeader";
import CommonFooter from "@/components/service/SpFooter";
import AppAlert from '@/components/portal/SpAppAlert.vue'
import MessageViewer from "@/components/common/MessageViewer";
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
export default {
  components: { CommonHeader, CommonFooter, MessageViewer, AppAlert, ExecutingOverlay },
  props: { parents: Object },
  setup() {
    const { getEnabledHeaderLinks, getPortalServiceImagePath, portalMenuMove } = require('@/helpers/portal/portal_util')
    const { pageMove, isSystemApp, getLogoFPortal, formatDateStrByDBTime } = require('@/helpers/common/common_util')
    const { readNotificationsListForBar, isLinkNotification, execLinkNotification,
      getNotificationServiceTypes, readNotificationStats } = require('@/helpers/common/notifications')
    const { initCreated, initMounted, readMenus, readNotifications, recording } = require('./home')
    return {
      getEnabledHeaderLinks, getPortalServiceImagePath, portalMenuMove,
      pageMove, isSystemApp, getLogoFPortal, formatDateStrByDBTime,
      readNotificationsListForBar, isLinkNotification, execLinkNotification,
      getNotificationServiceTypes, readNotificationStats,
      initCreated, initMounted, readMenus, readNotifications, recording,
    }
  },
  data: () => ({
    error: "",

    menus: [],
    test_menus: [],
    notifications: [],
    notifications_for_bar: null,
    is_mytask_navi: false,

    service_types: [],
    stats: [],

    // 録音用
    recordingFlg: false,
    recognition: null,
    voices: [
      {
        site: "foodsch",
        words: [
          "フーチャン",
          "ふーちゃん",
          "フーチャネ",
          "ふーちゃね",
          "フーズチャネル",
          "ふーずちゃねる",
          "フードチャネル",
          "ふーどちゃねる",
          "フードチャンネル",
          "ふーどちゃんねる",
          "FoodCHANNEL",
        ],
      },
      { site: "order", words: ["発注", "はっちゅう", "ハッチュウ"] },
    ],
  }),
  async created() {
    scrollTo(0, 0);
    await this.initCreated(this)
    this.readMenus(this);
    this.service_types = [
      { key: "", text: "すべての通知" },
      ...this.getNotificationServiceTypes(),
    ];
    this.stats = await this.readNotificationStats(true);
    await this.readNotifications(this);
    this.notifications_for_bar = await this.readNotificationsListForBar()
  },
  async mounted() {
    await this.initMounted(this)
  },
  methods: {
    clickRecording() {
      this.recording(this)
    },
    isExistStats() {
      return this.stats.filter(v => v.count > 0).length > 0
    },
    isExistServiceStats(item) {
      return (
        this.getServiceStatsNewCount(item) || this.getServiceStatsOldCount(item)
      );
    },
    getServiceStatsNewCount(item) {
      const count = this.getServiceStatsCount(item, "new");
      if (count) return count > 999 ? "999+" : count;
    },
    getServiceStatsOldCount(item) {
      const count = this.getServiceStatsCount(item, "old");
      if (count) return count > 999 ? "999+" : count;
    },
    getServiceStatsCount(item, oldnew) {
      if (!item.key) return;
      const stats = this.stats.find(
        (v) => v.oldnew == oldnew && v.service_type == item.key && v.count > 0
      );
      if (stats) return stats.count;
    },

    // 通知
    clickNotification(item) {
      let url = '/notification'
      if (item.key) url += '?type=' + item.key
      this.pageMove(url)
    },
  }
};
</script>

<style scoped>
.sp-microphone-btn-active {
  background: -webkit-linear-gradient(0deg, #ffa000, #fbc02d);
  width: 68px;
  height: 68px;
  line-height: 68px;
  border-radius: 100%;
  text-align: center;
}

.sp-microphone-btn-active i {
  font-size: 32px;
  color: #fff;
}

.sp-card-service-left {
  width: 40px;
  max-width: 40px;
  text-align: center;
}

.sp-card-service-left img {
  min-width: 40px;
  max-width: 40px;
}

.sp-card-service button {
  height: 100%;
}
</style>
