import { defineStore } from 'pinia';

export const useStoreSystem = defineStore('eatery-system', {
  persist: true,
  state: () => ({
    display_mode: null,
    // 通知バーの表示制御で使用（通知を非表示にした際に通知IDを保存する）
    before_notice_bar_key: null,

    route: null,
    router: null,
  }),
});