<template>
  <div>
    <!-- 通知区分：アナウンス通知 -->
    <div v-if="isDisplay && notifications_for_bar.notification_type === 1" class="header-alert header-alert-deletable">
      <div class="header-alert-body">
        <a class="header-alert-title" @click="clickNoticeLink(notifications_for_bar.link_url)">
          <span class="mr-2">{{ notifications_for_bar.title }}</span>
          <v-icon size="small">mdi-chevron-right</v-icon>
        </a>
        <div class="header-alert-date">
          <span>{{ noticeDateStr(notifications_for_bar.published_at) }}</span>
        </div>
      </div>
      <div class="header-alert-close">
        <button @click="closeNoticeBar(notifications_for_bar.notice_key)">
          <v-icon size="small">mdi-close</v-icon>
        </button>
      </div>
    </div>
    <!-- 通知区分：障害通知 -->
    <div v-if="notifications_for_bar.notification_type === 2" class="header-alert header-alert-undeletable">
      <div class="header-alert-body">
        <a class="header-alert-title" @click="clickNoticeLink(notifications_for_bar.link_url)">
          <v-icon size="small">mdi-alert</v-icon>
          <span class="mx-2">{{ notifications_for_bar.title }}</span>
          <v-icon size="small">mdi-chevron-right</v-icon>
        </a>
        <div class="header-alert-date">
          <span>最終更新: {{ noticeDateTimeStr(notifications_for_bar.published_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    notifications_for_bar: {
      type: Object
    }
  },
  setup() {
    const { pageMove, openExternalWindow } = require("@/helpers/common/common_util");
    const { storeGetBeforeNoticeBarKey, storeSetBeforeNoticeBarKey } = require("@/helpers/store/storeSystemHelper")
    return {
      pageMove, openExternalWindow,
      storeGetBeforeNoticeBarKey, storeSetBeforeNoticeBarKey,
    }
  },
  data: () => ({
    is_show_announce: true
  }),
  computed: {
    isDisplay() {
      return this.is_show_announce && this.notifications_for_bar.notice_key !== this.storeGetBeforeNoticeBarKey()
    }
  },
  methods: {
    noticeDateStr(value) {
      return value.substring(0, value.indexOf("T"))
    },
    noticeDateTimeStr(value) {
      const date_time = value.replace("T", ' ')
      return date_time.substring(0, value.lastIndexOf(":"))
    },
    closeNoticeBar(notice_key) {
      this.is_show_announce = false
      this.storeSetBeforeNoticeBarKey(notice_key)
    },
    clickNoticeLink(link_url) {
      if (!link_url) {
        this.pageMove('/notification')
        return
      }
      this.openExternalWindow(link_url)
    }
  }
}
</script>

<style scoped>
/* 通知枠 */
.header-alert {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 12px;
}

.header-alert-deletable {
  background-color: #FFE479;
}

.header-alert-undeletable {
  background-color: #D92A2A;
  color: #fff;
}

.header-alert-undeletable a,
.header-alert-undeletable i {
  color: #fff;
}

.header-alert-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-alert-title {
  display: flex;
  align-items: center;
}

.header-alert-body .header-alert-title {
  color: inherit;
}

.header-alert-close {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 24px;
}

.header-alert-close button {
  display: block;
  width: 100%;
  height: 100%;
}
</style>