export default
    [
        {
            path: '/auth/signin',
            name: 'auth_signin',
            component: () => import('@/components/auth/signin/IndexSignIn'),
            meta: { title: 'サインイン' },
        },

        {
            path: '/auth/signup',
            name: 'auth_signup',
            component: () => import('@/components/auth/signup/IndexSignUp'),
            meta: { title: 'アカウントを作成' },
        },

        {
            path: '/auth',
            component: () => import('@/components/auth/AuthIndex'),
            children: [
                {
                    path: 'forget_password',
                    name: 'auth_forget_password',
                    component: () => import('@/components/auth/forget_password/IndexForgetPassword'),
                    meta: { title: 'パスワード再設定' },
                },
            ]
        },

        {
            path: '/auth/signout',
            name: 'auth_signout',
            component: () => import('@/components/auth/pages/SignOut'),
        }, {
            path: '/auth/external/confirm',
            name: 'external_auth_confirm',
            component: () => import('@/components/auth/external_auth/ExternalAuthConfirm'),
        }, {
            path: '/auth/savetoken',
            name: 'auth_savetoken',
            component: () => import('@/components/auth/pages/SaveToken'),
        }, {
            path: '/auth/getuserinfo',
            name: 'auth_getuserinfo',
            component: () => import('@/components/auth/pages/GetUserInfo'),
            meta: { title: "最新の情報を取得" },
        },

        {
            path: '/setup/invite',
            name: 'auth_setup_invite',
            component: () => import('@/components/auth/setup/SetupInvite'),
            meta: { title: '招待コード確認' },
        }, {
            path: '/setup/account',
            name: 'AccountCreateComplete',
            component: () => import('@/components/auth/setup/SetupAccountIndex'),
            meta: { title: '初期設定' },
        }, {
            path: '/setup/profile',
            name: 'auth_setup_profile',
            component: () => import('@/components/auth/setup/IndexSetup'),
            meta: { title: '初期設定', page_type: "new_invitation" },
        },

        {
            path: '/proxy_signin',
            name: 'proxy_signin',
            component: () => import('@/components/auth/pages/ProxySignin'),
        }
    ]
