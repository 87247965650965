
import api_common from "@/helpers/api_common.js";

// アカウント設定
export function listAccount(apiconf, account_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/spec/list",
            query: { account_id: account_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function updateAccount(apiconf, account_id, specs) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "account/spec/update",
            query: { account_id: account_id, specs: specs },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 企業設定
export function getCompanyTaskScheduleUiVer(apiconf, staff_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "company/spec/task_schedule_ui_ver/get",
            query: { staff_id: staff_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function setCompanyTaskScheduleUiVer(apiconf, staff_id, spec_value) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "company/spec/task_schedule_ui_ver/set",
            query: { staff_id: staff_id, spec_value: spec_value },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// 店舗設定
export function listShop(apiconf, staff_id, shop_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "shop/spec/list",
            query: { staff_id: staff_id, shop_id: shop_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function updateShop(apiconf, staff_id, specs) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "shop/spec/update",
            query: { staff_id: staff_id, specs: specs },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

// スタッフ設定
export function listStaff(apiconf, staff_id) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "staff/spec/list",
            query: { staff_id: staff_id },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}
export function updateStaff(apiconf, staff_id, specs) {
    return new Promise(function (resolve, reject) {
        api_common.callPostApi({
            conf: apiconf,
            path: "staff/spec/update",
            query: { staff_id: staff_id, specs: specs },
        }).then(function (data) {
            resolve(data)
        }, function (err) {
            reject(err)
        });
    });
}

export default {
    listAccount,
    updateAccount,
    getCompanyTaskScheduleUiVer,
    setCompanyTaskScheduleUiVer,
    listShop,
    updateShop,
    listStaff,
    updateStaff,
}