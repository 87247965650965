<template>
  <div :class="{ sp: isDisplayModeSP() }">
    <router-view :parents="params" />
  </div>
</template>

<script>
export default {
  name: "PortalIndex",
  setup() {    
    const { isDisplayModeSP, setServiceBaseTitle } = require('@/helpers/common/common_util')
    const { getUserInfo } = require('@/helpers/auth/auth_util')
    return { isDisplayModeSP, setServiceBaseTitle, getUserInfo }
  },
  data: () => ({
    params: {
      userinfo: {
        company_name: null,
        brand_name: null,
        shop_name: null,
        staff_name: null,
      },
    },
  }),
  created() {
    this.setServiceBaseTitle();
    // this.params = {
    //   userinfo: this.getUserInfo(),
    // };
  },
  mounted() {
    this.setServiceBaseTitle();
  },
  updated() {
    this.setServiceBaseTitle();
  },
  methods: {},
};
</script>

<style scoped>
.sp {
  /* max-width: 390px; */
  /* width: 100%; */
  /* margin: 0 auto; */
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  min-height: 100vh;
}
</style>