import { useStoreAuth } from '@/store/auth.js';
const store = useStoreAuth();

import { isShopShareMode } from '@/helpers/auth/auth_util';
import { arrayConcat } from '@/helpers/common/common_util';
import { getApiValue, getApiValues } from '@/helpers/api_common';

// ロール
export function storeSetRoles(roles) {
    store.roles = roles;
}
export function storeGetRoles() {
    return store.roles;
}

// カスタムクレーム    
export function storeSetCustomClaims(custom_claims) {
    store.custom_claims = custom_claims;
}
export function storeGetCustomClaims() {
    return store.custom_claims;
}

// 従業員情報
export function storeSetEmployee(employee) {
    store.employee = employee;
}
export function storeGetEmployee() {
    return store.employee;
}

// プロファイル
export function storeSetProfile(profile) {
    store.profile_error = null;
    store.account = profile.account;
    store.associates = profile.associates
    store.user_sync = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
}
function _storeGetProfile(key) {
    if (store.profile && store.profile[key]) {
        return store.profile[key];
    }
    return null;
}
export function storeSetProfileError(error) {
    store.profile_error = error;
}
export function storeGetProfileError() {
    return store.profile_error
}

// スタッフグループ
export function storeSetStaffGroup(error) {
    store.staff_group = error;
}
export function storeGetStaffGroup() {
    return store.staff_group
}

// 共有ユーザ
export function storeSetShopStaffShareUser(staff_id, _share_user) {
    store.staff_share_user[staff_id] = _share_user
}
export function storeGetShopStaffShareUser(staff_id = storeGetKeyAsSelectedStaff()) {
    return store.staff_share_user[staff_id];
}

// スタッフロール
export function storeSetShopStaffRoles(staff_id, _roles) {
    store.staff_roles[staff_id] = _roles
}
export function storeGetShopStaffRoles(staff_id = storeGetKeyAsSelectedStaff()) {
    return store.staff_roles[staff_id];
}

// アカウント
export function storeGetAccount(key) {
    if (store.account && key) {
        return store.account[key]
    }
    return store.account
}
export function storeSetAccount(account) {
    store.account = account;
}
export function storeGetAccountId() {
    return storeGetAccount("account_id");
}
export function storeGetAccountUserName() {
    return _storeGetProfile("user_name");
}
export function storeGetDefaultPolicies() {
    return _storeGetProfile("default_policies");
}
export function storeGetAssociates() {
    return store.associates;
}
export function storeGetAssociate(associate_id) {
    if (associate_id && store.associates && store.associates[associate_id]) {
        return store.associates[associate_id]
    }
    return null;
}

// 店舗共有モード
export function storeSetShopShareMode(parent_staff_id, _share_staff) {
    store.shop_share[parent_staff_id] = _share_staff
}
export function storeGetShopShareMode(parent_staff_id) {
    return store.shop_share[parent_staff_id]
}

// 店舗共有モード用スタッフ取得
export function storeGetShopShareStaff() {
    return storeGetShopStaffShareUser()
}
export function storeGetShopShareStaffId() {
    const staff = storeGetShopShareStaff()
    return staff ? staff.staff_id : null
}

export function storeSetShopProfile(shop_profile) {
    if (shop_profile.shop_id) {
        store.shop_profile[shop_profile.shop_id] = shop_profile.profile
        store.selected_shop_id = shop_profile.shop_id
    }
}
export function storeGetShopProfile(shop_id) {
    return store.shop_profile[shop_id];
}
export function storeIsShopIdAccount() {
    // TODO:ちゃんとprofileから判定する
    return store.signin_shop_cd != null;
}
export function storeGetSelectedAnotherShopId() {
    return store.selected_shop_id;
}
export function storeSetSigninShopCd(signin_shop_cd) {
    store.signin_shop_cd = signin_shop_cd;
}
export function storeGetSigninShopCd() {
    return store.signin_shop_cd;
}
export function storeResetShopProfile() {
    store.shop_profile = {}
    store.selected_shop_id = null
    store.signin_shop_cd = null
}

// 店舗
export function storeGetShops(is_contain_selected) {
    let shops = []
    let selected_associate_id = storeGetKeyAsSelectedAssociate();
    let selected_staff_id = storeGetKeyAsSelectedStaff();
    let selected_shop_cd = storeGetKeyAsSelectedShop();
    let associates = storeGetAssociates();
    Object.keys(associates).forEach((associate_id) => {
        let associate = associates[associate_id];
        Object.keys(associate.staff).forEach((staff_id) => {
            let staff = associate.staff[staff_id];
            let company = getApiValues(staff.company[0])
            Object.keys(staff.shops).forEach((shop_cd) => {
                if (is_contain_selected ||
                    (selected_associate_id != associate_id &&
                        selected_staff_id != staff_id &&
                        selected_shop_cd != shop_cd)) {
                    shops.push({
                        associate_id: associate_id,
                        staff_id: staff_id,
                        shop_cd: shop_cd,
                        company_group_cd: company.company_group_cd,
                        company_cd: company.company_cd,
                        company_name: company.company_name,
                        shop_name: getApiValue(staff.shops[shop_cd], "shop_name"),
                    })
                }
            });
        });
    });
    return shops;
}

// ユーザ情報同期時刻
export function storeGetUserSync() {
    return store.user_sync;
}

// アプリ表示設定
export function storeSetAppSettings(app_settings) {
    store.app_settings = app_settings
}
export function storeGetAppSettings() {
    return store.app_settings;
}

// 表示選択
export function storeSetAccountSelectedShop(associate, staff, shop, company_group, company, brand, franchise_owner) {
    const account_selected_shop = {
        account_id: storeGetAccountId(),
        associate: associate,
        staff: staff,
        shop: shop,
        company_group: company_group,
        company: company,
        brand: brand,
        franchise_owner: franchise_owner,
    }
    let account_id = account_selected_shop.account_id;
    let selected_shop = JSON.parse(JSON.stringify(account_selected_shop));
    delete selected_shop.account_id
    store.account_selected_shop[account_id] = selected_shop
}
export function storeGetAccountSelectedShop(key) {
    let selected = store.account_selected_shop[storeGetAccountId()];
    if (selected) {
        if (key) {
            return selected[key];
        }
        return selected
    }
    return null
}
export function storeGetKeyAsSelectedAssociate() {
    return storeGetAccountSelectedShop('associate');
}
export function storeGetKeyAsSelectedStaff() {
    return storeGetAccountSelectedShop('staff');
}
export function storeGetKeyAsSelectedShop() {
    return storeGetAccountSelectedShop('shop');
}
export function storeGetKeyAsSelectedCompanyGroup() {
    return storeGetAccountSelectedShop('company_group');
}
export function storeGetKeyAsSelectedCompany() {
    return storeGetAccountSelectedShop('company');
}
export function storeGetKeyAsSelectedBrand() {
    return storeGetAccountSelectedShop('brand');
}
export function storeGetKeyAsSelectedFranchiseOwner() {
    return storeGetAccountSelectedShop('franchise_owner');
}

export function storeGetSelectedAssociate(key) {
    let associate = storeGetAssociate(storeGetKeyAsSelectedAssociate());
    if (associate) {
        if (key) {
            return associate[key];
        }
        return associate;
    }
    return null;
}
export function storeGetSelectedAssociateId() {
    return storeGetSelectedAssociate('associate_id');
}

export function storeGetSelectedStaff(key) {
    let associate = storeGetSelectedAssociate();
    if (associate) {
        let staff = associate.staff[storeGetKeyAsSelectedStaff()];
        if (staff) {
            if (key) {
                return staff[key];
            }
            return staff;
        }
    }
    return null;
}

export function storeGetSelectedShopId() {
    return storeGetSelectedAssociate('shop_id')
}
export function storeGetSelectedCompanyId() {
    return storeGetSelectedAssociate('company_id')
}


export function storeGetSelectedStaffPolicies() {
    return storeGetSelectedStaff("policies");
}
export function storeGetSelectedStaffId(real_staff = false) {
    if (!real_staff && isShopShareMode()) {
        return storeGetShopShareStaffId() ?? storeGetSelectedStaff("staff_id");
    } else {
        return storeGetSelectedStaff("staff_id");
    }
}
export function storeGetSelectedStaffSso() {
    let values = storeGetSelectedStaff("sso");
    return (values && values.length > 0) ? values[0] : null;
}
export function storeGetSelectedStaffCompany() {
    let values = storeGetSelectedStaff("company");
    return (values && values.length > 0) ? values[0] : null;
}
export function storeGetSelectedStaffAppSettings() {
    return storeGetSelectedStaff("app_settings");
}
export function storeGetSelectedStaffAppSettingsInvalidToolbars() {
    return getApiValue(
        storeGetSelectedStaffAppSettings(),
        "invalid_toolbars"
    );
}
export function storeGetSelectedStaffAvatar() {
    return getApiValue(storeGetSelectedStaff(), "avatar");
}

export function storeGetSelectedShopInfo() {
    return storeGetSelectedStaff("shop_info");
}

export function storeGetSelectedShop(key) {
    let staff = storeGetSelectedStaff();
    if (staff) {
        let shop = staff.shops[storeGetKeyAsSelectedShop()];
        if (shop) {
            if (key) {
                return shop[key];
            }
            return shop;
        }
    }
    return null;
}

// 選択ロール・ポリシー
export function storeGetSelectedRoles() {
    return storeGetShopStaffRoles()
}
export function storeGetSelectedRoleCds() {
    let cds = []
    const roles = storeGetShopStaffRoles()
    if (roles) {
        roles.forEach(r => {
            if (r.role_cd) {
                cds = arrayConcat(cds, r.role_cd.split(","))
            }
        })
    }
    return cds
}
export function storeGetSelectedPolicies() {
    let policies = []
    const roles = storeGetShopStaffRoles()
    if (roles) {
        roles.forEach(r => {
            policies = arrayConcat(policies, r.policies)
        })
    }
    return policies
}
export function storeGetSelectedLinks() {
    let links = []
    const roles = storeGetShopStaffRoles()
    if (roles) {
        roles.forEach(r => {
            links = arrayConcat(links, r.links)
        })
    }
    return links
}
export function storeGetSelectedLink(link_cd) {
    return storeGetSelectedLinks().find(v => v.link_cd == link_cd)
}

// 企業内ロール・ポリシー
export function storeGetSelectedCompanyRoleCds() {
    let cds = []
    const staff = storeGetSelectedAssociate("staff");
    Object.keys(staff).forEach((staff_id) => {
        const roles = staff[staff_id].roles
        if (roles) {
            roles.forEach(r => {
                if (r.role_cd) {
                    cds = arrayConcat(cds, r.role_cd.split(","))
                }
            })
        }
    })
    return cds
}

// フランチャイズ
export function storeGetSelectedCompanyFranchiseRights() {
    let franchise_rights = []
    const staff = storeGetSelectedAssociate("staff");
    Object.keys(staff).forEach((staff_id) => {
        if (staff[staff_id].shop_info.franchise_rights)
            franchise_rights.push(...staff[staff_id].shop_info.franchise_rights)
    })
    return franchise_rights
}

// サインイン種別（確定前）
export function storeSetPrevSigninType(prev_signin_type) {
    store.prev_signin_type = prev_signin_type
}
export function storeGetPrevSigninType() {
    return store.prev_signin_type
}

// サインイン
export function storeSetSigninInfo(signin_info) {
    store.signin_info = signin_info
}
export function storeGetSigninInfo() {
    return store.signin_info
}

// サインアウト
export function storeSignout() {
    store.token = {}
    store.token_sync = null
    store.id_token = null
    store.access_token = null
    store.refresh_token = null
    store.token_refresh_required = false
    store.external_username = null
    store.user_sync = null
    store.activate_status = null
    store.account = null
    store.custom_claims = null
    store.associates = null
    store.group_sso = null
    store.prev_redirect_param = null
    store.roles = null
    store.staff_share_user = {}
    store.staff_roles = {}
    store.account_specs = []
    store.company_specs = []
}
export function storeSetSignoutResult(result) {
    store.signout_result = result;
}
export function storeGetSignoutResult() {
    return store.signout_result
}

// サインアップ
export function storeSetSignupInfo(info) {
    store.signup_info = info;
}
export function storeGetSignupInfo() {
    return store.signup_info
}

// トークン
export function storeSetToken(token) {
    store.token = token
    store.token_sync = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
}
export function storeResetToken() {
    store.resetToken();
}
export function storeSetExternalUsername(username) {
    store.external_username = username;
}
export function storeGetTokenInfo() {
    return store.token
}
export function storeGetIdToken() {
    const token = storeGetTokenInfo()
    return token ? token.id_token : null
}
export function storeIsExistIdToken() {
    const idtoken = storeGetIdToken()
    return idtoken != undefined && idtoken != null && idtoken != ""
}
export function storeGetAccessToken() {
    const token = storeGetTokenInfo()
    return token ? token.access_token : null
}
export function storeGetRefreshToken() {
    const token = storeGetTokenInfo()
    return token ? token.refresh_token : null
}
export function storeGetTokenSync() {
    return store.token_sync
}
export function storeGetExternalUsername() {
    return store.external_username
}
export function storeGetCognitoUserId() {
    return store.cognito_userid
}
// トークンリフレッシュ要求
export function storeSetTokenRefreshRequired(token_refresh_required) {
    store.token_refresh_required = token_refresh_required;
}
export function storeGetTokenRefreshRequired() {
    return store.token_refresh_required;
}


// 情報更新後リダイレクト先
export function storeSetReloadRedirect(to) {
    store.reload_redirect = to;
}
export function storeGetReloadRedirect() {
    let redirect = store.reload_redirect;
    store.reload_redirect = null;
    return redirect;
}

// サイト間リダイレクト
export function storeSetSiteRedirectParam(param) {
    store.site_redirect_param = param;
}
export function storeGetSiteRedirectParam() {
    return store.site_redirect_param;
}

// 制御モード（Web/App）
export function storeSetSystemMode(system_mode) {
    store.system_mode = system_mode;
}
export function storeGetSystemMode() {
    return store.system_mode;
}

// デザインモード）
export function storeSetDesignMode(design_mode) {
    store.design_mode = design_mode;
}
export function storeGetDesignMode() {
    return store.design_mode;
}

// 設定
export function storeSetAccountSpecs(specs) {
    store.account_specs = specs
}
export function storeGetAccountSpecs() {
    return store.account_specs
}
export function storeSetCompanySpecs(specs) {
    store.company_specs = specs
}
export function storeGetCompanySpecs() {
    return store.company_specs
}

// お知らせ
export function storeSetNotificationStats(stats) {
    store.notification_stats = stats
}
export function storeGetNotificationStats() {
    return store.notification_stats
}

export default {
    // ロール
    storeSetRoles,
    storeGetRoles,

    // 従業員情報
    storeSetEmployee,
    storeGetEmployee,

    // カスタムクレーム    
    storeSetCustomClaims,
    storeGetCustomClaims,

    // プロファイル
    storeSetProfile,
    storeSetProfileError,
    storeGetProfileError,

    // スタッフグループ
    storeSetStaffGroup,
    storeGetStaffGroup,

    // 共有ユーザ
    storeSetShopStaffShareUser,
    storeGetShopStaffShareUser,

    // スタッフロール
    storeSetShopStaffRoles,
    storeGetShopStaffRoles,

    // アカウント
    storeGetAccount,
    storeSetAccount,
    storeGetAccountId,
    storeGetAccountUserName,
    storeGetDefaultPolicies,
    storeGetAssociates,
    storeGetAssociate,

    // 店舗共有モード
    storeSetShopShareMode,
    storeGetShopShareMode,

    // 店舗共有モード用スタッフ取得
    storeGetShopShareStaff,
    storeGetShopShareStaffId,
    storeSetShopProfile,
    storeGetShopProfile,
    storeIsShopIdAccount,
    storeGetSelectedAnotherShopId,
    storeSetSigninShopCd,
    storeGetSigninShopCd,
    storeResetShopProfile,

    // 店舗
    storeGetShops,

    // ユーザ情報同期時刻
    storeGetUserSync,

    // アプリ表示設定
    storeSetAppSettings,
    storeGetAppSettings,

    // 表示選択
    storeSetAccountSelectedShop,
    storeGetAccountSelectedShop,
    storeGetKeyAsSelectedAssociate,
    storeGetKeyAsSelectedStaff,
    storeGetKeyAsSelectedShop,
    storeGetKeyAsSelectedCompanyGroup,
    storeGetKeyAsSelectedCompany,
    storeGetKeyAsSelectedBrand,
    storeGetKeyAsSelectedFranchiseOwner,
    storeGetSelectedAssociate,
    storeGetSelectedAssociateId,
    storeGetSelectedStaff,
    storeGetSelectedShopId,
    storeGetSelectedCompanyId,
    storeGetSelectedStaffPolicies,
    storeGetSelectedStaffId,
    storeGetSelectedStaffSso,
    storeGetSelectedStaffCompany,
    storeGetSelectedStaffAppSettings,
    storeGetSelectedStaffAppSettingsInvalidToolbars,
    storeGetSelectedStaffAvatar,
    storeGetSelectedShopInfo,
    storeGetSelectedShop,

    // 選択ロール・ポリシー
    storeGetSelectedRoles,
    storeGetSelectedRoleCds,
    storeGetSelectedPolicies,
    storeGetSelectedLinks,
    storeGetSelectedLink,

    // 企業内ロール・ポリシー
    storeGetSelectedCompanyRoleCds,

    // フランチャイズ
    storeGetSelectedCompanyFranchiseRights,

    // サインイン種別（確定前）
    storeSetPrevSigninType,
    storeGetPrevSigninType,

    // サインイン
    storeSetSigninInfo,
    storeGetSigninInfo,

    // サインアウト
    storeSignout,
    storeSetSignoutResult,
    storeGetSignoutResult,

    // サインアップ
    storeSetSignupInfo,
    storeGetSignupInfo,

    // トークン
    storeSetToken,
    storeResetToken,
    storeSetExternalUsername,
    storeGetTokenInfo,
    storeGetIdToken,
    storeIsExistIdToken,
    storeGetAccessToken,
    storeGetRefreshToken,
    storeGetTokenSync,
    storeGetExternalUsername,
    storeGetCognitoUserId,

    // トークンリフレッシュ要求
    storeSetTokenRefreshRequired,
    storeGetTokenRefreshRequired,

    // 情報更新後リダイレクト先
    storeSetReloadRedirect,
    storeGetReloadRedirect,

    // サイト間リダイレクト
    storeSetSiteRedirectParam,
    storeGetSiteRedirectParam,

    // 制御モード（Web/App）
    storeSetSystemMode,
    storeGetSystemMode,

    // デザインモード
    storeSetDesignMode,
    storeGetDesignMode,

    // 設定
    storeSetAccountSpecs,
    storeGetAccountSpecs,
    storeSetCompanySpecs,
    storeGetCompanySpecs,

    // お知らせ
    storeSetNotificationStats,
    storeGetNotificationStats,
}