import storeActivate from "@/helpers/store/storeActivateHelper"
import storeAuth from "@/helpers/store/storeAuthHelper"
import commonApi from "@/helpers/common/common_api"
import { isSystemApp, isDisplayModeSP } from "@/helpers/common/common_util"
import { isProxySigninAccount } from "@/helpers/auth/auth_util"
import { checkInviteQr, getPortalHomeMenus, portalMenuMove } from "@/helpers/portal/portal_util"
import { isEnvProd } from "@/helpers/environment"
import { debugLog } from "@/helpers/common/datadog"
// import { readNotificationsList } from "@/helpers/common/notifications"

// 作成時初期設定
export async function initCreated(self) {
  if (!isSystemApp()) {
    let SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) self.recognition = new SpeechRecognition();
  }
  readInviteError(self)
}

// 描画時初期設定
export async function initMounted(self) {
  if (isSystemApp()) {
    // アプリ音声イベント
    window.finishVoiceApp = (text, error) => {
      if (!error) {
        _searchTextEvent(self, text);
      }
      self.recordingFlg = false;
    };

    // アプリ招待QR読み込みイベント
    window.finishQRApp = (text, error) => {
      if (!error) {
        checkInviteQr(text);
      }
    };
  }
}

function readInviteError(self) {
  const invite_error = storeActivate.storeGetActivateInviteError()
  if (invite_error) {
    switch (invite_error) {
      case 1: // 招待IDが存在しない
      case 2: // 招待期限切れ
        self.error = "利用できない招待コードです。"
        break
      case 3: // 既に所属済の店舗
        self.error = "既に所属済の店舗です。"
        break
      case 93: // 既に従業員登録されている状態での従業員招待はエラー
        self.error = "すでに従業員として登録されている為、こちらの招待コードは利用できません。"
        break
      case 91: // 所属企業追加エラー
      case 92: // 所属店舗追加エラー
      case 99: // 例外エラー
      default:
        self.invite_error = "店舗参加に失敗しました。しばらくしてから再度実施してください。";
    }
    storeActivate.storeSetActivateInviteError();
  }
}

export function readMenus(self) {
  self.menus = getPortalHomeMenus();
  // テスト用設定
  if (!isEnvProd()) {
    self.test_menus = [
      {
        title: "ファイルライブラリ",
        to: "/apptest/photo",
        icon: "mdi-image-album",
      },
      {
        title: "QR読み込み",
        to: "/apptest/qrread",
        icon: "mdi-microphone",
      },
      // {
      //   title: "AWS Polly",
      //   to: "/awstest/polly",
      //   icon: "mdi-text-to-speech",
      // },
      {
        title: "お知らせ投稿",
        to: "/test/notification",
        icon: "mdi-bell-plus-outline",
      },
      {
        title: "表示モード設定",
        to: "/test/displaymode",
        icon: "mdi-cellphone-cog",
      },
      {
        title: "プロファイル表示",
        to: "/test/displayprofile",
        icon: "mdi-card-bulleted",
      },
      {
        title: "アプリ音声テスト",
        to: "/test/appvoicetotext",
        icon: "mdi-microphone",
      },
      {
        title: "ムービー再生",
        to: "/test/movie-player",
        icon: "mdi-movie",
      },
      {
        title: "日付ピッカー",
        to: "/test/date-picker",
        icon: "mdi-calendar",
      },
      {
        title: "エラーログ出力",
        to: "/test/errorlog",
        icon: "mdi-math-log",
      },
      {
        title: "ユーザエージェント",
        to: "/test/ua",
        icon: "mdi-account-cash",
      },
      {
        title: "V-Manage利用申込",
        to: "/request/vmanage",
        icon: "mdi-cube-send",
      },
      {
        title: "メンテナンス",
        external: "/maintenance/" + (isDisplayModeSP() ? "sp/" : ""),
        icon: "mdi-sleep",
      },
      {
        title: "PDFプレビュー",
        to: "/test/pdf-preview",
        icon: "mdi-file-document",
      },
      {
        title: "バージョン同期確認",
        to: "/test/sync-version",
        icon: "mdi-sync",
      },
    ];
    if (!isProxySigninAccount()) {
      self.test_menus.push({
        title: "代理ログイン",
        to: "/test/proxysignin",
        icon: "mdi-account",
      })
    }
  }
}

// 通知読み込み
export async function readNotifications(self) {
  let notifications = await commonApi.apiNotificationSearch({ page: 1, limit: 3, });
  if (notifications.data.length == 0) {
    self.notifications = [
      {
        message: { message: "お知らせはありません" },
        inactive: true,
      },
    ];
  } else {
    notifications.data.map(v => v.message_text = v.message.created_by.toLowerCase() == 'v-manage' ? v.message.post_text : v.message.title)
    self.notifications = notifications.data
  }
}

// 録音制御
export function recording(self) {
  if (isSystemApp()) {
    if (!self.recordingFlg) {
      _appStartRecording(self);
    } else {
      _appCancelRecording(self);
    }
  } else {
    if (!self.recordingFlg) {
      _startRecording(self);
    } else {
      _finishRecording(self);
    }
  }
}

// アプリ音声
function _appStartRecording(self) {
  voiceApp.postMessage(""); // eslint-disable-line
  self.recordingFlg = true;
}
function _appCancelRecording(self) {
  if (self.recordingFlg) {
    voiceApp.postMessage("cancel"); // eslint-disable-line
    self.recordingFlg = false;
  }
}

// PC音声
function _startRecording(self) {
  if (!self.recordingFlg) {
    self.recognition.onresult = (event) => {
      _searchTextEvent(
        self,
        event.results[0][0].transcript.replaceAll(" ", "")
      );
    };
    self.recognition.onend = () => {
      if (self.recordingFlg) {
        self.recordingFlg = false;
      }
    };
    self.recordingFlg = true;
    self.recognition.start();
  }
}
function _finishRecording(self) {
  if (self.recordingFlg) {
    self.recognition.stop();
  }
}

// テキストイベント検知
function _searchTextEvent(self, text) {
  debugLog("text", text);
  // TODO:APIが出来上がるまでは内部判定
  let target = self.voices.find((v) =>
    v.words.find((word) => !text.indexOf(word))
  );
  if (target) {
    const link = storeAuth.storeGetSelectedLink(target.site);
    if (link) {
      portalMenuMove(self, link);
    }
  }
}

export default {
  initCreated,
  initMounted,
  readMenus,
  readNotifications,
  recording,
}
