<template>
  <div>
    <footer class="sp-footer-menus">
      <div v-for="(link, i) in getPortalSpFooterLinks()" :key="i">
        <button @click="clickLink(link)" :class="{ active: link.active }">
          <div>
            <v-icon v-if="link.icon">{{ link.icon }}</v-icon>
            <img v-if="link.image" :src="link.image" style="width: 18px" />
          </div>
          <div class="mt-2">
            {{ link.name }}
          </div>
        </button>
      </div>
    </footer>
    <ExecutingOverlay ref="execute" />
  </div>
</template>

<script>
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
export default {
  components: { ExecutingOverlay },
  setup() {
    const { getPortalSpFooterLinks, portalMenuMove } = require('@/helpers/portal/portal_util')
    return {
      getPortalSpFooterLinks, portalMenuMove
    }
  },
  methods: {
    clickLink(link) {
      if (!link.active) {
        this.portalMenuMove(this, link);
      }
    },
  },
};
</script>

