import api_common from "@/helpers/api_common.js";

export default {

    // ルーム一覧
    getRoomList(apiconf, staff_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/room/list",
            query: { staff_id: staff_id },
        })
    },

    // ルーム作成
    createRoom(apiconf, staff_id, room, members) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/room/create",
            query: {
                staff_id: staff_id,
                room: room,
                members: members,
            },
        })
    },

    // ルーム更新
    updateRoom(apiconf, staff_id, room, isdelete) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/room/update",
            query: {
                staff_id: staff_id,
                room: room,
                delete: isdelete,
            },
        })
    },

    // メッセージ一覧
    getMessageList(apiconf, staff_id, room_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/message/list",
            query: { staff_id: staff_id, room_id: room_id, page: page },
        })
    },

    // メッセージ送信
    sendMessage(apiconf, staff_id, room_id, post_text, mentions, url_links, attached_files, shared_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    room_id: room_id,
                    post_text: post_text,
                    mentions: mentions,
                    url_links: url_links,
                    shared_files: shared_files,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "chat/message/post",
            formdata: formdata,
        })
    },

    // メッセージ更新
    updateMessage(apiconf, staff_id, room_id, chat_key, post_text, mentions, isdelete) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/message/update",
            query: {
                staff_id: staff_id,
                room_id: room_id,
                chat_key: chat_key,
                post_text: post_text,
                mentions: mentions,
                delete: isdelete,
            },
        })
    },

    // ファイルプレビュー
    previewFile(apiconf, staff_id, room_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/chat/file",
            query: {
                staff_id: staff_id,
                room_id: room_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // ルームメンバー変更
    roomMemberAdd(apiconf, staff_id, room_id, member_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/event/add/member",
            query: {
                staff_id: staff_id,
                room_id: room_id,
                member_id: member_id,
            },
        })
    },
    roomMemberDelete(apiconf, staff_id, room_id, member_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/event/rm/member",
            query: {
                staff_id: staff_id,
                room_id: room_id,
                member_id: member_id,
            },
        })
    },

    // リアクション
    addReaction(apiconf, staff_id, chat_key, emoji) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/event/add/like",
            query: {
                staff_id: staff_id,
                chat_key: chat_key,
                emoji: emoji,
            },
        })
    },
    removeReaction(apiconf, staff_id, chat_key, emoji) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "chat/event/rm/like",
            query: {
                staff_id: staff_id,
                chat_key: chat_key,
                emoji: emoji,
            },
        })
    },
}