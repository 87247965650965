export default
    [
        {
            path: '/redirect/btob',
            name: 'redirect-btob',
            component: () => import('@/components/portal/redirect/RedirectBtobId'),
            meta: { title: '' },
        }
    ]

