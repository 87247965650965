import config from "@/configs/common"
import storeAuth from "@/helpers/store/storeAuthHelper"
import axios from "axios";
import _async from 'async';
import { debugLog, errorLog } from '@/helpers/common/datadog'
import { arrayConcat, redirectMaintenancePage, routerPush, copyJson } from '@/helpers/common/common_util'
import {
  isProxySigninAccount, readCompanyProfile, getUserProfileData, setDisplayShop,
  resetSelectedShopInfo, redirectSignout
} from '@/helpers/auth/auth_util'
import { reloadCompanySpec, reloadAccountSpec } from '@/helpers/auth/spec'
import auth_api from '@/helpers/auth/auth_api'

let _verified_id_token = null;

// POST API呼び出し
export function callPostApi(params) {
  params.headers = params.headers ?? {};
  params.throwallcheck = params.throwallcheck ?? false;
  params.checktoken = params.checktoken ?? true;
  params.checkerror = params.checkerror ?? true;
  params.checkpolicy = params.checkpolicy ?? true;
  params.nolog = params.nolog ?? false;
  params.headers["Content-Type"] = "application/json"

  return new Promise(function (resolve, reject) {
    // IDトークン取得＆リフレッシュ
    _getIdTokenWithRefresh(params.checktoken, _verified_id_token ?? params.id_token).then(function (id_token) {
      if (params.checktoken) {
        params.query.id_token = id_token
      }
      _setQuerySourceVersion(params.query)
      _callApiPost(params).then(function (results) {
        resolve(results)
      }, function (err) {
        reject(err)
      })
    }, function (err) {
      reject(err)
    })
  });
}

// （Form形式）POST API呼び出し
export function callPostFormApi(params) {
  params.headers = params.headers ?? {};
  params.checktoken = params.checktoken ?? true;
  params.checkerror = params.checkerror ?? true;
  params.checkpolicy = params.checkpolicy ?? true;
  params.nolog = params.nolog ?? false;
  params.headers["Content-Type"] = "multipart/form-data"

  return new Promise(function (resolve, reject) {
    // IDトークン取得＆リフレッシュ
    _getIdTokenWithRefresh(params.checktoken, _verified_id_token ?? params.id_token).then(function (id_token) {
      const formdata = new FormData();
      params.formdata.forEach(item => {
        if (item.set_token) {
          item.value.id_token = id_token
        }
        _setQuerySourceVersion(item.value)
        formdata.append(item.column, item.is_json ? JSON.stringify(item.value) : item.value);
      })
      params.query = formdata
      _callApiPost(params).then(function (results) {
        resolve(results)
      }, function (err) {
        reject(err)
      })
    }, function (err) {
      reject(err)
    });
  })
}

// API POST実施
export function _callApiPost(params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        buildApiUrl(params.conf, params.path), params.query, params.headers
      )
      .then(function (response) {
        if (!params.nolog) {
          debugLog("api", params.path, response)
        }

        // メンテナンス中
        if (response.status == 302) {
          redirectMaintenancePage()
          return reject("now maintenance")
        }

        // チェック全スルー指定
        if (params.throwallcheck) {
          return resolve(response.data)
        }

        // サインイン時のF-Portal認証不可判定
        if (params.path == 'auth/signin' && response.data.error == "not authorized.") {
          return reject("f-portal not authorized.")
        }

        // アカウント認証チェック
        if (params.checkaccount == true && response.data.account_verify != 0) {
          reject(response.data.results)
          return
        }

        // 署名チェック
        if (response.data.error == "Signature verification failed.") {
          reject(response.data.error)
          return
        }

        // トークンチェック
        if (params.checktoken && response.data.token_verify != 0) {
          // サインアウト
          storeAuth.storeSetSignoutResult("認証の有効期限が切れました。")
          storeAuth.storeSetToken({
            id_token: null,
            access_token: storeAuth.storeGetAccessToken(),
            refresh_token: storeAuth.storeGetRefreshToken(),
            expires_in: null
          })
          redirectSignout();
          reject("token failed")
          return
        }

        // ポリシー＆制約チェック
        if (params.checkpolicy && response.data.policy_verify != 0) {
          reject("policy failed")
          return
        }
        if (params.checkpolicy && response.data.limit_verify != 0) {
          if (response.data.results.desc == "over capacity.") {
            return reject(response.data.results.desc)
          }
          return reject("limit failed")
        }

        // エラーチェック
        if (params.checkerror) {
          if (response.data.error) {
            reject(params.error_results ? response.data : response.data.error)
            return
          } else if (response.data.results.error) {
            reject(params.error_results ? response.data.results : response.data.results.error)
            return
          }
        }

        // if (response.data.api_revision) response.data.results.api_revision = response.data.api_revision
        resolve(response.data.results)
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 302") {
          redirectMaintenancePage()
          return reject("now maintenance")
        }
        if (!params.nolog) {
          errorLog("api", params.path, err)
        }
        reject(err)
      });
  })
}

// （ファイル取得）POST API呼び出し
export async function callPostResponseFile(params) {
  params.headers = params.headers ?? {};
  params.checktoken = params.checktoken ?? true;
  params.checkerror = params.checkerror ?? true;
  params.nolog = params.nolog ?? false;
  params.headers["Content-Type"] = "application/json"
  params.headers["responseType"] = 'blob'

  const id_token = await _getIdTokenWithRefresh(params.checktoken, _verified_id_token ?? params.id_token)
  if (params.checktoken) {
    params.query.id_token = id_token
  }
  _setQuerySourceVersion(params.query)
  try {
    const response = await axios
      .post(
        buildApiUrl(params.conf, params.path), params.query, params.headers
      )

    if (!params.nolog) {
      debugLog("preview", params.path, response)
    }

    // メンテナンス中
    if (response.status == 302) {
      redirectMaintenancePage()
      throw new Error("now maintenance")
    }

    // エラーチェック
    if (params.checkerror) {
      if (response.data.type == 'application/json') {
        const text = await response.data.text()
        if (JSON.parse(text).error) {
          throw new Error(JSON.parse(text).error)
        }
      }
    }
    return response
  } catch (err) {
    if (err.message == "Request failed with status code 302") {
      redirectMaintenancePage()
      return new Error("now maintenance")
    }
    throw err
  }
}

// GET API呼び出し
export function callGetApi(params) {
  params.headers = params.headers ?? {};
  params.checkerror = params.checkerror ?? true;
  params.nolog = params.nolog ?? false;
  return new Promise(function (resolve, reject) {
    axios.create(params.headers)
      .get(
        buildApiUrl(params.conf, params.path)
      )
      .then(function (response) {
        if (!params.nolog) {
          debugLog("api", params.path, response)
        }

        // メンテナンス中
        if (response.status == 302) {
          redirectMaintenancePage()
          return reject("now maintenance")
        }

        // エラーチェック
        if (params.checkerror) {
          if (response.data.error) {
            reject(response.data.error)
            return
          } else if (response.data.results.error) {
            reject(response.data.results.error)
            return
          }
        }
        resolve(response.data)
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 302") {
          redirectMaintenancePage()
          return reject("now maintenance")
        }
        if (!params.nolog) {
          errorLog("api", params.path, err)
        }
        reject(err)
      });
  });
}

// S3署名付きURL呼び出し
export async function callS3PresignedUrl(presigned_url) {
  const req = axios.create({ 'responseType': 'blob' })
  const res = await req.get(presigned_url)
  return res
}

// トークン取得（＆リフレッシュトークン）
export function _getIdTokenWithRefresh(is_need, id_token) {
  return new Promise(function (resolve, reject) {
    if (id_token) {
      return resolve(id_token);
    }
    if (!is_need) {
      resolve(null)
      return
    }
    // 保持IDトークン取得
    if (!storeAuth.storeGetIdToken()) {
      reject()
      return
    }

    // アカウント未作成時
    if (!storeAuth.storeGetSelectedStaffId()) {
      resolve(storeAuth.storeGetIdToken());
      return
    }

    // 代理ログイン中
    if (isProxySigninAccount()) {
      resolve(storeAuth.storeGetIdToken());
      return
    }

    // リフレッシュ有無判定
    let diff = new Date().getTime() - new Date(storeAuth.storeGetTokenSync()).getTime();
    let sec = Math.abs(diff) / 1000;
    if (sec <= config.token_refresh_term_sec) {
      resolve(storeAuth.storeGetIdToken());
      return
    }
    debugLog("token取得から", sec, "秒経過")

    // トークンリフレッシュ
    auth_api.apiTokenRefresh().then(function (results) {
      if (!results.error) {
        storeAuth.storeSetToken({
          id_token: results.AuthenticationResult.IdToken,
          access_token: results.AuthenticationResult.AccessToken,
          refresh_token: storeAuth.storeGetRefreshToken(),
          expires_in: results.AuthenticationResult.ExpiresIn
        })
        resolve(storeAuth.storeGetIdToken())
      } else {
        // サインアウト
        storeAuth.storeSetSignoutResult("認証の有効期限が切れました。")
        storeAuth.storeSetToken({
          id_token: null,
          access_token: storeAuth.storeGetAccessToken(),
          refresh_token: storeAuth.storeGetRefreshToken(),
          expires_in: null
        })
        redirectSignout();
        reject()
      }
    }, function () {
      // サインアウト
      storeAuth.storeSetSignoutResult("認証の有効期限が切れました。")
      storeAuth.storeSetToken({
        id_token: null,
        access_token: storeAuth.storeGetAccessToken(),
        refresh_token: storeAuth.storeGetRefreshToken(),
        expires_in: null
      })
      redirectSignout();
      reject()
    })
  })
}

// APIパラレル実行
export async function apiParallelCall(calls, parallel_max = 10) {
  async function _call(key, callback) {
    try {
      const result = { key: key, ret: await calls[key].method(...calls[key].params ?? []) }
      if (callback) callback(null, result)
      return result;
    } catch (err) {
      if (callback) callback(err)
      return err;
    }
  }
  return new Promise(function (resolve, reject) {
    _getIdTokenWithRefresh(true).then(function (id_token) {
      _verified_id_token = id_token
      _async.mapLimit(Object.keys(calls), parallel_max, _call, function (err, results) {
        if (!err) {
          let ret = {}
          for (const result of results) {
            ret[result.key] = result.ret
          }
          resolve(ret)
        } else {
          reject(err)
        }
      });
      _verified_id_token = null
    }, function (err) {
      reject(err)
    })
  });
}

// ファイルプレビューパラレル実行
export async function previewParallel(calls, parallel_max = 3) {
  async function _call(values, callback) {
    try {
      await values.method(...values.params ?? [])
      values.callback(values.params)
      if (callback) callback(null, true);
      return true
    } catch (err) {
      if (callback) callback(null, false);
      return false
    }
  }
  return new Promise(function (resolve, reject) {
    _getIdTokenWithRefresh(true).then(function (id_token) {
      _verified_id_token = id_token
      _async.mapLimit(calls, parallel_max, _call, function () {
        // 個別の成否は無視
        resolve()
      });
      _verified_id_token = null
    }, function (err) {
      reject(err)
    })
  });
}

// API URL生成
export function buildApiUrl(apiconf, path) {
  let url = apiconf.baseurl;
  if (url.slice(-1) != "/") {
    url += "/"
  }
  if (apiconf.version) {
    url += apiconf.version;
  }
  if (url.slice(-1) != "/") {
    url += "/"
  }
  return url + path;
}

// APIデータ成形取得
export function getApiValue(elem, key) {
  if (elem) {
    if (elem[key]) {
      return elem[key];
    } else if (elem.values && elem.values[key]) {
      return elem.values[key];
    }
  }
  return null;
}
export function getApiValues(elem) {
  if (!elem) {
    return null;
  }
  let _elem = copyJson(elem);
  if (_elem.values) {
    Object.keys(_elem.values).forEach(function (key) {
      if (!_elem[key]) {
        _elem[key] = _elem.values[key];
      }
    })
    delete _elem.values;
  }
  return _elem;
}
export function getArrayApiValues(array) {
  let results = []
  if (array) {
    array.forEach(elem => {
      results.push(getApiValues(elem));
    })
  }
  return results;
}

// APIデータソート
export function sortApiValues(elem, key, isasc = true) {
  elem.sort(function (a, b) {
    let va = getApiValue(a, key);
    let vb = getApiValue(b, key);
    if (isasc) {
      if (va > vb) return 1;
      if (va < vb) return -1;

    } else {
      if (va > vb) return -1;
      if (va < vb) return 1;
    }
    return 0;
  });
}

// 一覧ループ取得
export async function apiGetListLoop(func, start_page = 1, limit_page = 10) {
  return new Promise(function (resolve, reject) {
    return getListLoop(start_page).then(function (ret) {
      if (ret.data) {
        return resolve(ret)
      } else {
        return reject(ret)
      }
    })
  })

  async function getListLoop(start_page) {
    let page = start_page
    let results = {
      current_page: 0,
      data: [],
      datacount: 0,
      datalimit: 0,
      max_page: 0,
      totalcount: 0,
    }
    try {
      while (page) {
        const result = await func(page)
        results.readed = true
        results.current_page = result.current_page
        results.datalimit = result.datalimit
        results.totalcount = result.totalcount
        results.max_page = result.max_page
        results.datacount += result.datacount
        results.data = arrayConcat(results.data, result.data)
        if (result.max_page <= page || limit_page <= page) {
          return results
        }
        page++
      }
    } catch (err) {
      return err
    }
  }
}

// ページング取得
export async function paginationLoad(api_method, pagination, conditions) {
  let results
  if (pagination.limit > 0) {
    conditions.page = pagination.page;
    conditions.limit = pagination.limit;
    results = await api_method(conditions);
  } else {
    results = {
      current_page: 1,
      datalimit: conditions.limit,
      max_page: 1,
      data: [],
      datacount: 0,
      totalcount: 0,
    };
    // MEMO:無限ループ防止（最大10000件まで）
    conditions.page = 1;
    conditions.limit = 100;
    for (let page = 1; page <= 100; page++) {
      conditions.page = page;
      const result = await api_method(conditions);
      results.totalcount = result.totalcount;
      results.datacount += result.datacount;
      results.data = arrayConcat(results.data, result.data);
      if (result.max_page <= page) break;
    }
  }
  pagination.data = results.data
  pagination.max_page = results.max_page;
  pagination.totalcount = results.totalcount;
  pagination.datacount = results.datacount;
  if (results.max_page <= pagination.page)
    pagination.page = results.max_page;
  pagination.loaded++;
  return pagination
}


// 代理ログイン
export async function authProxySignin(apiconf, from_staff_id, proxy_signin_associate_id) {
  let url = apiconf.baseurl;
  if (url.slice(-1) != "/") {
    url += "/"
  }
  url += "api/admin/auth/proxy/signin"
  const results = await axios.post(url,
    {
      id_token: storeAuth.storeGetIdToken(),
      staff_id: from_staff_id,
      associate_id: proxy_signin_associate_id
    },
    { "Content-Type": "application/json" }
  )
  // ユーザプロファイル保持
  await readCompanyProfile(results.data.results.data.profile);
  const profile = getUserProfileData(results.data.results.data.profile)
  profile.account.proxy_signin = proxy_signin_associate_id
  profile.account.proxy_from_staff = from_staff_id
  storeAuth.storeSetProfile(profile);
  // 表示店舗設定
  await setDisplayShop()
  // 選択店舗情報再設定
  await resetSelectedShopInfo();
  // 企業・アカウント設定読み込み
  await reloadCompanySpec()
  await reloadAccountSpec()
  routerPush("/")
}

// ローカルソースバージョン設定
function _setQuerySourceVersion(query) {
  query.front_version = config.source_version
}

export default {
  callPostApi,
  callPostFormApi,
  _callApiPost,
  callPostResponseFile,
  callGetApi,
  callS3PresignedUrl,
  _getIdTokenWithRefresh,
  apiParallelCall,
  previewParallel,
  buildApiUrl,
  getApiValue,
  getApiValues,
  getArrayApiValues,
  sortApiValues,
  apiGetListLoop,
  paginationLoad,
  authProxySignin,
}